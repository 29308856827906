<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ $i18n.translate("Organizations") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          dense
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Organizations')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" small fab v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <OrganizationTypesField
                  dense
                  v-model="filterByOrganizationTypes"
                  label="Organization Types"
                  @input="onSubmit"
                ></OrganizationTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="effectiveItems"
                  v-model="filterByEffective"
                  :label="$i18n.translate('Active') + ' / ' + $i18n.translate('Inactive')"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-if="hasFilters">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn class="mr-2" small fab v-on="{ ...onTooltip }" @click="onClearSearch">
            <v-icon>mdi-magnify-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Clear Search") }}</span>
      </v-tooltip>
      <v-select
        class="show-columns"
        label="Show Columns"
        dense
        :items="showHeaders"
        v-model="selectedHeaders"
        clearable
        multiple
        hide-details
        flat
        solo-inverted
        return-object
        @change="initHeaders"
      >
        <template v-slot:selection="data">{{ showSelected(data) }}</template>
      </v-select>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
      >
        <template v-slot:item.organizationKey="{ item }">
          <router-link :to="{ name: 'participantManagement', query: { organizationId: item.id } }">
            <span v-if="item.legacyOrganizationKey">
              {{ item.legacyOrganizationKey }}
            </span>
            <span v-else>
              {{ item.organizationKey }}
            </span>
          </router-link>
        </template>
        <template v-slot:item.organizationType="{ item }">
          {{ item.organizationType.name }}
        </template>

        <template v-slot:item.address="{ item }">
          {{ item.address ? item.address.cityRegionPostalCountry : "" }}
        </template>
        <template v-slot:item.phoneNumber1="{ item }">
          {{ item.phoneNumber1 | toPhoneNumber }}
        </template>
        <template v-slot:item.cpg="{ item }">
          <span v-for="cpg in item.organizationGroups" :key="cpg.id">
            {{ cpg.name }}
          </span>
        </template>
        <template
          v-for="index in selectedProgram.organizationCustomFields.length"
          v-slot:[getCustomFieldSlotName(index)]="{ item }"
        >
          <span :key="index">
            {{
              $customField.renderValue(
                selectedProgram.organizationCustomFields[index - 1],
                item["customFieldValue" + index]
              )
            }}
          </span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip color="primary" v-if="item.effective">{{ $i18n.translate("Active") }}</v-chip>
          <v-chip color="danger" v-else>{{ $i18n.translate("Inactive") }}</v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style>
.show-columns {
  max-width: 20%;
}
</style>

<script>
import { mapGetters } from "vuex";
import OrganizationTypesField from "../../gapp-components/components/fields/OrganizationTypesField.vue";

export default {
  components: { OrganizationTypesField },
  name: "CompanyManagement",
  metaInfo: {
    title: "Dealer Management"
  },
  data() {
    return {
      search: "",
      filterByOrganizationTypes: [],
      filterByOrganizationGroups: [],
      loading: false,
      items: [],
      accountManagerItems: [],
      filterByEffective: "Active",
      effectiveItems: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "All", value: "All" }
      ],
      nameFilter: "",
      headers: [],
      total: 0,
      options: {
        itemsPerPage: 10
      },
      firstFetch: false,
      showHeaders: [],
      selectedHeaders: []
    };
  },
  watch: {
    options: {
      handler() {
        if (this.firstFetch) {
          this.fetchData();
        }
      },
      deep: true
    }
  },
  methods: {
    showSelected(v) {
      if (v.index == 0) {
        return this.selectedHeaders.length + " Selected";
      }
      return "";
    },
    initShowHeaders() {
      this.showHeaders = [];

      let size = this.selectedProgram.organizationCustomFields.length;
      for (let i = 1; i <= size; i++) {
        let organizationCustomField = this.selectedProgram.organizationCustomFields[i - 1];
        if (organizationCustomField.name) {
          this.showHeaders.push({
            value: "customFieldValue" + i,
            text: this.$i18n.translate(organizationCustomField.name),
            sortable: true,
            align: this.$customField.alignValue(organizationCustomField)
          });
        }
      }
    },
    initHeaders() {
      this.headers = [];
      this.headers.push({
        value: "organizationKey",
        text: "Key",
        sortable: true
      });
      this.headers.push({
        value: "organizationType",
        text: "Type",
        sortable: true
      });
      this.headers.push({
        value: "name",
        text: "Name",
        sortable: true
      });
      this.headers.push({
        value: "address",
        text: "Address",
        sortable: false
      });

      this.headers.push({
        value: "phoneNumber1",
        text: "Phone",
        sortable: false
      });

      let size = this.selectedHeaders.length;
      for (let i = 0; i < size; i++) {
        this.headers.push(this.selectedHeaders[i]);
      }

      this.headers.push({
        value: "status",
        text: "Status",
        sortable: true,
        align: "center"
      });
    },
    getCustomFieldSlotName(index) {
      let fieldValue = "item.customFieldValue" + index;
      return fieldValue;
    },
    onClear() {
      this.search = "";
      this.fetchData();
    },
    onSubmit() {
      this.options.page = 1;
      this.fetchData();
    },
    onClearSearch() {
      this.search = "";
      this.filterByOrganizationTypes = [];
      this.filterByOrganizationGroups = [];
      this.onSubmit();
    },
    fetchData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {};
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }
      if (this.filterByOrganizationTypes && this.filterByOrganizationTypes.length > 0) {
        filters.organizationTypes = this.filterByOrganizationTypes.map(organizationType => {
          return {
            id: organizationType.id
          };
        });
      }
      if (this.filterByOrganizationGroups && this.filterByOrganizationGroups.length > 0) {
        filters.organizationGroups = this.filterByOrganizationGroups.map(organizationGroup => {
          return {
            id: organizationGroup.id
          };
        });
      }
      filters.isEffective = this.filterByEffective;

      let currentFiscalYear = this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
      let isCurrentFiscalYear = this.selectedFiscalYear.fiscalYear == currentFiscalYear;

      if (!isCurrentFiscalYear) {
        filters.createdAfter = this.selectedFiscalYear.fiscalYearStart;
        filters.createdBefore = this.selectedFiscalYear.fiscalYearEnd;
      }

      this.$api
        .post(
          "/api/organizations/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
          this.firstFetch = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchOrganizationType(key) {
      return this.$api.post("/api/organizationTypes/search?size=1", { organizationTypeKey: key }).then(({ data }) => {
        return data && data.content.length == 1 ? data.content[0] : null;
      });
    }
  },
  created() {
    if (this.$route.query.organizationTypeKey != undefined) {
      this.fetchOrganizationType(this.$route.query.organizationTypeKey).then(organizationType => {
        if (organizationType) {
          this.filterByOrganizationTypes.push(organizationType);
          this.filterByEffective = "Active";
        }
        this.onSubmit();
      });
    } else {
      this.onSubmit();
    }
  },
  mounted() {
    this.initShowHeaders();
    if (this.showHeaders && this.showHeaders.length > 9) {
      this.selectedHeaders.push(this.showHeaders[9]);
    }
    this.initHeaders();

    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Admin"
      },
      { text: "Company Management" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedClient", "selectedFiscalYear"]),
    hasFilters() {
      let check =
        (this.search && this.search.length > 0) ||
        (this.filterByOrganizationTypes && this.filterByOrganizationTypes.length > 0) ||
        (this.filterByOrganizationGroups && this.filterByOrganizationGroups.length > 0);

      return check;
    }
  }
};
</script>
